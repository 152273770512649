import { Accessor, createSignal, Match, onMount, Show, Switch } from "solid-js";
import {
  coinIcon,
  giftUnwrap,
  HubbleFullSvg,
  ribbonConfetti,
} from "~/assets/assets";
import { VoucherGenerationBrandCard } from "~/components/brand_l2/voucher_generation_brand_card";
import HubbleImage from "~/widgets/hubble_image";
import { ButtonDark } from "../verification/components";
import { classNames } from "~/utils/etc";
import { openIntercom } from "~/utils/third_party/intercom";
import { CoinsTransactionsModal } from "~/components/coins_transactions_modal";
import { toIndianNumber } from "~/utils/number";
import { useModal } from "~/components/modal";
import { formatDateLong } from "~/utils/date";
import { ThreeDotLoader } from "~/widgets/button";
import { Header } from "~/types";
import { getV3GiftingDataStorageKey } from "~/components/gifting/brand-collection-landing/gift_box_v3_landing_route_data";
import LocalStorageUtil from "~/utils/local_storage";
import { APIError } from "~/utils/fetch";
import { PhosphorIcon } from "~/widgets/icons";

export type CardProps = {
  heroImageUrl?: string;
  plainLogoUrl: string;
  cardBackgroundColor?: string;
  title: string;
  borderRadius?: number;
};

export function CardGiftV3(props: CardProps) {
  let borderRadius = props.borderRadius || 16;
  return (
    <Switch>
      <Match when={props.heroImageUrl}>
        <div
          class={`w-full rounded-[${borderRadius}px] border border-[#232323]`}
        >
          <HubbleImage
            class={`aspect-[335/195] w-full rounded-[${borderRadius - 1}px]`}
            src={props.heroImageUrl!}
            alt={props.title}
          />
        </div>
      </Match>
      <Match when={true}>
        <VoucherGenerationBrandCard
          plainLogoUrl={props.plainLogoUrl}
          cardBackgroundColor={
            props.cardBackgroundColor || "bg-white dark:bg-gray-800"
          }
          showHubbleLogo={true}
        />
      </Match>
    </Switch>
  );
}

export function GiftLottie() {
  return (
    <div class="pointer-events-none h-[80px] min-h-[80px] w-[311px] overflow-hidden  ">
      <lottie-player
        id="gift-unwrap"
        class="translate-y-[-70%] scale-[225%]"
        src={giftUnwrap}
        autoplay={false}
        loop={false}
      ></lottie-player>
    </div>
  );
}

export function AnimatedHeight() {
  const [height, setHeight] = createSignal(false);
  onMount(async () => {
    await new Promise((resolve) => setTimeout(resolve, 100));
    setHeight(true);
  });
  return (
    <div
      class="w-1 overflow-hidden  transition-all duration-[1100ms]"
      classList={{
        "mt-[450px]": height(),
        "h-0": !height(),
      }}
    >
      &nbsp;
    </div>
  );
}

export function giftReceivedText(props: {
  shrinkButtonHorizontally: Accessor<boolean>;
}) {
  return (
    <div
      class="flex  justify-center text-clip   text-center text-h3 font-medium text-white transition-all duration-300"
      classList={{
        "w-[0]": props.shrinkButtonHorizontally(),
        "w-full": !props.shrinkButtonHorizontally(),
      }}
    >
      <span class="line-clamp-1 overflow-hidden truncate ">
        You received a gift
      </span>
    </div>
  );
}

export function UnwrapButton(props: {
  onClickUnwrapGift: () => void;
  shrinkButtonVertically: Accessor<boolean>;
  shrinkButtonHorizontally: Accessor<boolean>;
  isLoading: boolean;
}) {
  return (
    <div class=" my-6 flex h-[48px] w-full flex-col  items-center justify-center px-1   ">
      <div
        class="overflow-hidden transition-all duration-300"
        classList={{
          "h-[2px]": props.shrinkButtonVertically(),
          "h-[48px]": !props.shrinkButtonVertically(),
          "w-[0]": props.shrinkButtonHorizontally(),
          "w-full": !props.shrinkButtonHorizontally(),
        }}
      >
        <ButtonDark
          onClick={props.onClickUnwrapGift}
          isLoading={props.isLoading}
        >
          Yay! Unwrap my gift
        </ButtonDark>
      </div>
    </div>
  );
}

export function DescriptionText(props: {
  text: string;
  animateDescription: Accessor<boolean>;
}) {
  return (
    <div
      class="flex justify-center transition-all duration-300"
      classList={{
        "opacity-100 scale-100 traslate-y-0 ": props.animateDescription(),
        "opacity-0 scale-75 translate-y-5  ": !props.animateDescription(),
      }}
    >
      <p class={classNames(" text-center text-[24px] font-medium ")}>
        {props.text}
      </p>
    </div>
  );
}

export function Footer(props: { spacedOut?: boolean }) {
  return (
    <div
      class="flex flex-col items-center justify-center gap-3 text-medium font-normal text-textNormal"
      classList={{
        "gap-7": props.spacedOut,
      }}
    >
      <span
        onClick={() => {
          openIntercom();
        }}
        class="cursor-pointer underline-offset-2	"
      >
        Need any help? <u>Contact support</u>
      </span>

      <div
        onClick={() => {
          window.open("https://www.myhubble.money", "_blank");
        }}
        class="flex cursor-pointer flex-row items-center justify-center py-1"
      >
        <span class={`pr-1 font-[Inter] font-semibold `}>Powered by</span>
        <HubbleFullSvg class="h-[14px]" fill="#7C7C7C" />
      </div>
    </div>
  );
}

export function BalancePill(props: {
  coinsAvailable: number;
  name: string;
  setRefreshCoins: () => void;
  large: boolean;
  giftingKey: string;
  glow?: Accessor<boolean>;
}) {
  const { openModal, setThemeMode } = useModal()!;

  let glowStyle = {
    border: "1px solid var(--Base-Secondary-Light, #ABABAB)",
    background: "#2A1E05",
    "box-shadow": "0px 0px 30px 0px rgba(255, 191, 66, 0.30)",
  };

  return (
    <div
      onClick={(event) => {
        event.stopPropagation();
        setThemeMode("dark");

        openModal(() => {
          return (
            <CoinsTransactionsModal
              setRefreshCoins={props.setRefreshCoins}
              balance={props.coinsAvailable}
              userName={props.name}
              enableRedeemCode={false}
              showExpiresOn={false}
              title="Your gift balance"
              subtitle="Use this balance to get gift card(s) of your preferred brand & buy the product you want with the gift card"
              headers={{
                [Header.SessionId]: LocalStorageUtil.getItem(
                  getV3GiftingDataStorageKey(props.giftingKey, "sessionId")
                ),
              }}
              onError={(error) => {
                if (error instanceof APIError) {
                  if (error.code === 401) {
                    window.location.href =
                      "/gift-box/brand-collection/" + props.giftingKey;
                  }
                }
              }}
            />
          );
        });
      }}
      class="flex cursor-pointer flex-row  items-center rounded-[50px] border border-basePrimaryMedium bg-black text-mediumBold transition-all  duration-300 "
      style={
        props.glow?.() ?? false
          ? glowStyle
          : {
              "box-shadow": "0px 2px 11px 0px rgba(0, 0, 0, 0.05)",
            }
      }
      classList={{
        "p-[6px]  pr-3": props.large,
        "px-[2px] pr-2": !props.large,
        "h-[36px]": props.large,
        "h-[28px]": !props.large,
      }}
    >
      <img
        src={coinIcon}
        class="pr-[6px]"
        classList={{
          "h-7 w-7": !props.large,
          "h-8 w-8": props.large,
        }}
      />
      <Show when={props.large}>Balance: </Show>
      {toIndianNumber(props.coinsAvailable)}
    </div>
  );
}

export function BalanceAndTitle(props: {
  coinsAvailable: number;
  giftingKey: string;
  instructions: string[];
  showToolTip: Accessor<boolean>;
  setShowToolTip: (value: boolean) => void;
}) {
  return (
    <div class="mb-6 mt-5 flex flex-col items-center justify-start  transition-opacity duration-300">
      <BalancePill
        coinsAvailable={props.coinsAvailable}
        name="Hubble Code"
        setRefreshCoins={() => {}}
        large={true}
        giftingKey={props.giftingKey}
        glow={props.showToolTip}
      />
      {buildTootTip()}
      <div class="mt-7"></div>
      {buildTitle()}
    </div>
  );

  function buildTootTip() {
    return (
      <div
        class="relative flex flex-col items-start gap-[10px]  rounded-xl bg-basePrimaryDark px-3  transition-all duration-300 "
        classList={{
          "max-h-0": !props.showToolTip(),
          "py-3": props.showToolTip(),
          "mt-4": props.showToolTip(),
          "overflow-y-hidden": !props.showToolTip(),
          "duration-[500ms]": props.showToolTip(),
          "opacity-0": !props.showToolTip(),
        }}
      >
        {props.instructions.map((instruction, index) => {
          return buildText(instruction, index);
        })}
        <div class="absolute left-[50%] top-[-6px] h-[14.14px] w-[14.14px] translate-x-[-50%] rotate-45 bg-basePrimaryDark"></div>
        <PhosphorIcon
          class="absolute right-2  top-2 cursor-pointer text-baseTertiaryLight opacity-40"
          name="x"
          fontSize={18}
          onClick={() => {
            props.setShowToolTip(false);
            LocalStorageUtil.setItem(
              getV3GiftingDataStorageKey(props.giftingKey, "showTooltip"),
              false
            );
          }}
        />
      </div>
    );
  }

  function buildText(text: string, index: number) {
    return (
      <div class="flex w-full flex-row items-start justify-start gap-[6px]">
        <div class="flex h-[18px] w-[18px] items-center justify-center rounded-full bg-baseSecondaryLight text-[10px] font-semibold text-basePrimaryDark ">
          {index + 1}
        </div>
        <span class="flex-[1] text-[13px] font-normal tracking-[-0.15px] text-white">
          {text}
        </span>
      </div>
    );
  }

  function buildTitle() {
    return (
      <div class="relative flex w-full flex-row items-center justify-center gap-2">
        <div class="absolute  left-0  ">
          <DecorationSvg />
        </div>
        <span class="px-3 text-center text-[12px] font-medium uppercase tracking-[0.72px] text-[#757575]">
          Get from your choice of brand
        </span>
        <div class="absolute  right-0 rotate-180">
          <DecorationSvg />
        </div>
      </div>
    );
  }

  function DecorationSvg() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="46"
        height="7"
        viewBox="0 0 46 7"
        fill="none"
      >
        <path
          d="M0.113247 3.5L3 0.613249L5.88675 3.5L3 6.38675L0.113247 3.5ZM46 4H3V3H46V4Z"
          fill="#757575"
        />
      </svg>
    );
  }
}

export function RibbonConfettiLottie() {
  return (
    <lottie-player
      id="ribbon-confetti"
      src={ribbonConfetti}
      autoplay={false}
      loop={false}
      class="scale-[150%]"
    ></lottie-player>
  );
}

export function ExpiryDate(props: {
  expiryDate?: string;
  showExpiryOnBottom: Accessor<boolean>;
}) {
  return (
    <Show when={props.expiryDate}>
      <div class="fixed bottom-4 left-0 right-0 flex justify-center ">
        <span
          classList={{
            "translate-y-20": !props.showExpiryOnBottom(),
          }}
          class="text-f12 text-white duration-300"
        >{`Link expires on ${formatDateLong(new Date(props.expiryDate!))}`}</span>
      </div>
    </Show>
  );
}

export function LandingPageLoadingState(props: { bgimg: string }) {
  return (
    <div class=" flex h-full w-full flex-col items-center justify-center bg-black">
      <img src={props.bgimg} class="pointer-events-none absolute top-0" />
      <ThreeDotLoader color="#fff" />
    </div>
  );
}
